<template>
  <div id="mian">
    <div>
      <div class="con_from">
        <p>
          <i>机构名称</i>
          <el-input v-model="input" maxlength="128" style="width:200px;height:36px" suffix-icon="el-icon-search" placeholder="机构名称/机构编号"></el-input>
          <span class="jianju"></span>
          <i>执行状态</i>
          <el-select v-model="value" placeholder="全部" style="width:200px;height:36px;">
            <el-option label="全部" value=""></el-option>
						<el-option label="初始" value="初始"></el-option>
						<el-option label="执行中" value="执行中"></el-option>
						<el-option label="异常" value="异常"></el-option>
						<el-option label="执行完毕" value="执行完毕"></el-option>
          </el-select>
        </p>
        <p>
          <i>对账日期</i>
          <el-date-picker style="width:200px;height:36px;" v-model="query.startTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择时间">
          </el-date-picker>
          <span style="padding-left:10px;padding-right:10px;">-</span>
          <el-date-picker style="width:200px;height:36px" v-model="query.endTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择时间">
          </el-date-picker>
          <el-button class="searchBt">查询</el-button>
        </p>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>对账日期</p>
            </td>
            <td>
              <p>任务编码</p>
            </td>
            <td>
              <p>机构名称</p>
              <p>机构编号</p>
            </td>
            <td>
              <p>处理总数</p>
              <p>差错总数</p>
            </td>
            <td>
              <p>对账类型</p>
            </td>
            <td>
              <p>退款依据</p>
            </td>
            <td>
              <p>执行时间</p>
              <p>结束时间</p>
            </td>
            <td>
              <p>执行状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2019-01-12 15:51:15</p>
              <p>2019-01-12 15:51:59</p>
            </td>
            <td>
              <p>深圳有数互动科技有限公司</p>
            </td>
            <td>
              <p>12345678901234567</p>
              <p>0000678901234567</p>
            </td>
            <td>
              <p>-</p>
              <p>-</p>
            </td>
            <td>
              <p>微信扫码支付</p>
            </td>
            <td>
              <p>支付成功</p>
            </td>
            <td>
              <p>55.1</p>
            </td>
            <td>
              <p>否</p>
            </td>
            <td>
              <p><i class="lianjie" @click="editShow=true">编辑</i> <i class="lianjie" style="padding-left:7px">重新执行</i></p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
       :page-sizes="[10, 20, 50, 100]"  :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <el-dialog title="编辑" :visible.sync="editShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">任务编码</div>
          <div>XXX</div>
          <div class="overAccount_ul_title">对账类型</div>
          <el-select v-model="value" placeholder="选择对账类型">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li class="ul-right">
          <div style="height: 52px;"></div>
          <div class="overAccount_ul_title">退款依据</div>
          <el-select v-model="value" placeholder="选择退款依据">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="editShow = false">取 消</el-button>
        <el-button class="addBt" @click="editShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
	import {
		formatDate
	} from '@/utils/common.js'
  export default {
    data() {
      return {
				query:{
					startTime:formatDate(+new Date(),'yyyy-MM-dd 00:00:00'),
					endTime:formatDate(+new Date(),'yyyy-MM-dd 23:59:59')
				},
        value1: "",
        value2: "",
        crumbs: "批量订单查询",
        editShow: false, //修改
      }
    },
    methods: {
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
    }
  }
</script>
<style scoped>
  /* .con_from1{
    padding-top:20px; 
     width: 100%;
    height: 80px;
    position: relative;
    overflow: hidden;
} */
  .active {
    color: rgba(72, 184, 182, 1);
    border-bottom: 3px solid #48B8B6;
  }

  .lianjie {
    color: #008AFF;
		cursor: pointer;
  }

  .tab tr td p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }

  .tab_title {
    background: rgba(244, 245, 246, 1);
    border-top: 1px solid rgba(220, 224, 230, 1);
  }

  .tab1 .tab tr {
    padding-left: 20px;
    height: 60px;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
  }

  .tab {
    border-collapse: collapse;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
  }

  .tab1 {
    width: 100%;
    min-height: 600px;
    margin-top: 20px;
		margin-bottom: 20px;
  }

  .jiaoyi_box p b {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 600;
    color: rgba(72, 184, 182, 1);
  }

  .jiaoyi_box p i {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
  }

  .jiaoyi_box p {
    padding-top: 13px;
    width: 100%;
    text-align: center;
  }

  .jiaoyi {
    width: 105%;
    height: 90px;
    position: relative;
    margin-top: 20px;
  }

  .jiaoyi_box {
    float: left;
    width: 45%;
    height: 90px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  .jianju {

    padding-left: 110px;
  }

  .con_from p i {
    padding-right: 12px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }

  .con_from p {
    padding-top: 20px;
    padding-left: 15px;
  }

  .header span {
    display: inline-block;
    height: 100%;
    width: 120px;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }

  .header {
    line-height: 40px;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
    position: relative;
    top: 0;
  }

  #mian {
    padding-left: 20px;
    padding-right: 21px;
    width: 96.5%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .jianju2 {
    display: inline-block;
    width: 51px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
  }

  .searchBt {
    float: right;
    background: #48B8B6;
    border-color: #48B8B6;
    margin-left: 20px;
    width: 90px;
    height: 36px;
    padding: 0;
    color: #FFFFFF;
  }

  .addBt {
    width: 60px;
    height: 32px;
    background-color: #48B8B6;
    color: #FFFFFF;
    font-size: 12px;
    padding: 0;
  }

  .exitBt {
    width: 60px;
    height: 32px;
    background-color: #ffffff;
    color: #333333;
    font-size: 12px;
    padding: 0;
  }

  .overAccount_ul {
    overflow: hidden;
  }

  .overAccount_ul_title {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    color: #999999;
  }

  .overAccount_ul .el-input,
  .overAccount_ul .el-select {
    margin-bottom: 7px;
  }

  .ul_left {
    float: left;
    width: 200px;
    margin-left: 24px;
  }

  .ul-right {
    float: right;
    width: 200px;
    margin-right: 26px;
  }

  .el-pagination {
    margin-bottom: 15px;
  }
</style>
